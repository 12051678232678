<script lang="jsx">
import { democracyNav } from '@/config/nav'
import { getWaitList } from '@/api/api_democracy'
import TojoyInnerLayout from '@/components/layout/inner'

export default {
  name: 'democracy-index-view',

  components: {
    TojoyInnerLayout
  },

  data() {
    return {
      navList: democracyNav.children
    }
  },
  created() {
    this.navApproveRed()
  },
  methods:{
    //待我审批页面红点
    async navApproveRed() {
      const { code, data } = await getWaitList()
      if (code === '000000') {
        if(data?.list.length){
          this.navList[1].badge = true
        }else{
          this.navList[1].badge = false
        }
      }
    }

  },

  render(h) {
    return (
      <tojoy-inner-layout
        ref="tojoy-inner-layout"
        class="democracy-index-view"
        navList={this.navList}
        options={{breadcrumb:true, nav:true}}
      />
    )
  },
}
</script>

<style lang="scss">
.democracy-index-view {
  .errorPage {
    width: auto !important;
    margin: 20px 30px !important;
    border-radius: 5px;
  }
  .ol__desc-list {
    font-size: 14px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 14px;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .info-desc {
      &__dist {
        width: 2px;
        height: 2px;
        display: block;
        background: $darkgrey;
        flex: none;
        margin-right: 4px;
        margin-left: 3px;
        margin-top: 7px;
        border-radius: 50%;
      }
      &__text {
        line-height: 18px;
        word-break: break-all;
      }
    }
  }
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
