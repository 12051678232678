<template>
	<tojoy-inner-layout
		v-if="loaded"
		ref="tojoy-inner-layout"
		class="democracy-index-view"
		:breadcrumb-list="breadcrumbList"
		:navList="navList"
		:options="{breadcrumb:true, nav:true}"
	/>
</template>

<script>

import { myTarget } from '@/config/config.js';
import { targetNav } from '@/config/nav'
import TojoyInnerLayout from '@/components/layout/inner';
import { ApiTargetYearList } from '../../api/api_target';

export default {
  name: 'democracy-index-view',

  components: {
    TojoyInnerLayout
  },

  data() {
    return {
        breadcrumbList: myTarget.breadcrum,
        navList:targetNav.children,
	    loaded: false
    }
  },

  watch: {},

  mounted() {
	  this.getPageList()
  },
	methods:{
      	// 获取是否有年度目标数据
		getPageList (page = 1){
			const params={
				page,
				size:10,
			}
			ApiTargetYearList(params).then(res=>{
				if(res.code==='000000'){
					if(res.data?.list && res.data?.list.length=== 0){
						this.navList = this.navList.filter((item)=>{
							return item.id !='1-3'
						})
					}
				this.loaded = true
				}
			})
		},
	}
}
</script>

<style lang="scss">
.democracy-index-view {
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
